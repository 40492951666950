<template>
  <section class="coding-page">
    <h2 class="title">Coding Projects</h2>

    <section id="projects-section" class="fade-in-bottom">
        <div class="card" style="width: 600px">
            <img class="card-img-top" src="@/assets/coding/LandingPage.png" alt="Meal Planner">
            <div class="card-body">
            <a href="https://github.com/ATaylorN/capstone3_mealplanner"><h4 class="card-title">Meal Planner</h4></a>
            <img src="@/assets/icons/bootstrap.png" alt="Bootstrap icon">
            <img src="@/assets/icons/intellij.png" alt="IntelliJ icon">
            <img src="@/assets/icons/javascript.png" alt="JavaScript icon">
            <img src="@/assets/icons/java.png" alt="Java icon">
            <img src="@/assets/icons/html.png" alt="HTML icon">
            <img src="@/assets/icons/figma.png" alt="Figma icon">
            <img src="@/assets/icons/vue.png" alt="Vue icon">
            <img src="@/assets/icons/vscode.png" alt="VS Code icon">
            <img src="@/assets/icons/css.png" alt="CSS icon">
            <img src="@/assets/icons/pgadmin.png" alt="PGAdmin icon">
            <p class="card-text">Utilized Java, Spring Boot, PostgreSQL, & Vue.js to develop a meal planner web application 
                where a user can view, add, & update recipes with ease. In the 'Build a Recipe' 
                page, the user can search for ingredients & the information/images are supplied by an API. By simply dragging & dropping 
                selected recipes onto the calendar, users can plan out a full month's worth of meals. 
                With the option to select a date range, the application will display the necessary ingredients needed for that period. To 
                further streamline the process, users can print or email the grocery list, & even download a ICS file that is compatible with Google Calendar.</p>
            <a href="https://github.com/ATaylorN/capstone3_mealplanner" class="btn">View Project</a>
            </div>
        </div>

        <div class="card" style="width: 600px">
            <img class="card-img-top" style="height: 300px" src="@/assets/coding/landing-page.png" alt="Flash Cards">
            <div class="card-body">
            <a href="https://github.com/ATaylorN/flashcards"><h4 class="card-title">Flash Cards</h4></a>
            <img src="@/assets/icons/bootstrap.png" alt="Bootstrap icon">
            <img src="@/assets/icons/intellij.png" alt="IntelliJ icon">
            <img src="@/assets/icons/javascript.png" alt="JavaScript icon">
            <img src="@/assets/icons/java.png" alt="Java icon">
            <img src="@/assets/icons/html.png" alt="HTML icon">
            <img src="@/assets/icons/figma.png" alt="Figma icon">
            <img src="@/assets/icons/vue.png" alt="Vue icon">
            <img src="@/assets/icons/vscode.png" alt="VS Code icon">
            <img src="@/assets/icons/css.png" alt="CSS icon">
            <img src="@/assets/icons/pgadmin.png" alt="PGAdmin icon">
            <p class="card-text">Using Java, Spring Boot, 
                 and PostgreSQL for the backend and Vue.js for the frontend, I created an app where the user can choose 
                 to have random flashcards to cycle through or chose "Study Mode". "Study Mode" allows a user to cycle 
                 through questions and mark whether or not they got the question right. When the user opts to exit, the
                  user is shown a list of all of the questions that they got incorrect so that they know what they need
                   to continue studying. There's also an option for the user to really put their knowledge to the test
                    with a quiz.</p>
            <a href="https://github.com/ATaylorN/flashcards" class="btn">View Project</a>
            </div>
        </div>

          <div class="card" style="width: 600px">
            <img class="card-img-top" style="height: 300px" src="@/assets/coding/portfolio.jpg" alt="Portfolio Site">
            <div class="card-body">
            <a href="https://github.com/ATaylorN/Portfolio2024/tree/main/portfolio2024"><h4 class="card-title">Portfolio Site</h4></a>
            <img src="@/assets/icons/bootstrap.png" alt="Bootstrap icon">
            <img src="@/assets/icons/javascript.png" alt="JavaScript icon">
            <img src="@/assets/icons/html.png" alt="HTML icon">
            <img src="@/assets/icons/figma.png" alt="Figma icon">
            <img src="@/assets/icons/vue.png" alt="Vue icon">
            <img src="@/assets/icons/vscode.png" alt="VS Code icon">
            <img src="@/assets/icons/css.png" alt="CSS icon">
            <p class="card-text">My goal is to create a portfolio website that highlights not only the all of the coding projects that 
                I've worked on, but the marketing that I've worked on and the numerous portraits that I've created. I really want to highlight 
                that I'm a software developer that also has an eye for design.</p>
            <a href="https://github.com/ATaylorN/Portfolio2024/tree/main/portfolio2024" class="btn">View Project</a>
            </div>
        </div>

        <div class="card" style="width: 600px">
            <img class="card-img-top" style="height: 300px" src="@/assets/coding/library.png" alt="Library">
            <div class="card-body">
            <a href="https://github.com/ATaylorN/library_side_project"><h4 class="card-title">Library</h4></a>
            <img src="@/assets/icons/bootstrap.png" alt="Bootstrap icon">
            <img src="@/assets/icons/intellij.png" alt="IntelliJ icon">
            <img src="@/assets/icons/javascript.png" alt="JavaScript icon">
            <img src="@/assets/icons/java.png" alt="Java icon">
            <img src="@/assets/icons/html.png" alt="HTML icon">
            <img src="@/assets/icons/figma.png" alt="Figma icon">
            <img src="@/assets/icons/vue.png" alt="Vue icon">
            <img src="@/assets/icons/vscode.png" alt="VS Code icon">
            <img src="@/assets/icons/css.png" alt="CSS icon">
            <img src="@/assets/icons/pgadmin.png" alt="PGAdmin icon">
            <p class="card-text">
                 The end goal is to have an application where I can have a library catalog of all of the books that I own. I want to have the ability to 
                view them based on whether or not they've been read and be able to browse them based
                 on things like genre and series. I'd also like to add a wishlist component to it so that I can keep
                  track of books that I'm interested in purchasing and have it keep a running total of price. 
                 </p>
            <a href="https://github.com/ATaylorN/library_side_project" class="btn">View Project</a>
            </div>
        </div>
    </section>


<!-- COMING SOON: I WANT TO DO MORE OF THESE PROJECTS FIRST -->
    <!-- <section class="frontend">
        <div class="top">
        <h3>Frontend Mentor Projects</h3>
        <img src="@/assets/icons/bootstrap.png">
        <img src="@/assets/icons/javascript.png">
        <img src="@/assets/icons/html.png">
        <img src="@/assets/icons/figma.png">
        <img src="@/assets/icons/vue.png">
        <img src="@/assets/icons/css.png">
        </div>

        <div class="bottom">
        <img src="@/assets/coding/portfolio.png">
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. A adipisci ab ut ea sequi ipsa 
            perferendis recusandae doloremque voluptate dolorem reiciendis architecto tempora praesentium
             incidunt, veniam nihil quidem. Pariatur, eos.Lorem ipsum dolor sit amet consectetur adipisicing elit. A adipisci ab ut ea sequi ipsa 
            perferendis recusandae doloremque voluptate dolorem reiciendis architecto tempora praesentium
             incidunt, veniam nihil quidem. Pariatur, eos.<br>
             <br><b>What I Learned:</b><br>
             Lorem ipsum dolor sit amet consectetur adipisicing elit. A adipisci ab ut ea sequi ipsa 
            perferendis recusandae doloremque voluptate dolorem reiciendis architecto tempora praesentium
             incidunt, veniam nihil quidem. Pariatur, eos. Lorem ipsum dolor sit amet consectetur adipisicing elit. A adipisci ab ut ea sequi ipsa 
            perferendis recusandae doloremque voluptate dolorem reiciendis architecto tempora praesentium
             incidunt, veniam nihil quidem. Pariatur, eos.Lorem ipsum dolor sit amet consectetur adipisicing elit. A adipisci ab ut ea sequi ipsa 
            perferendis recusandae doloremque voluptate dolorem reiciendis architecto tempora praesentium
             incidunt, veniam nihil quidem. Pariatur, eos.</p>
        </div>
    </section> -->
  
  </section>
</template>

<script>
export default {
    name: 'CodingProjects'
}
</script>

<style scoped>
.frontend{
    background-color: #9EB5BC;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Share Tech Mono';
    gap: 2rem;
}
.bottom p{
    width: 50rem;
}
.bottom img{
    max-width: 600px;
}
.top img{
    width: 30px;
}
.bottom{
    display: flex;
    flex-wrap: wrap;
    margin: 2rem 0;
    gap: 2rem;
    font-family: 'Roboto Condensed', sans-serif;
    color: white;
    font-size: 18px;
}
.title{
    text-align: center;
    background-color: #384C52;
    color: white;
    font-family: 'Share Tech Mono';
    padding: 2rem;
    margin-bottom: 2rem;
}
.card {
    box-shadow: 0px 5px 20px 0px black;
    border: none;
}
a{
    text-decoration: none;
    color: black;
}
a:hover{
    color: #9EB5BC;
}
#projects-section{
    display: flex;
    flex-wrap: wrap;
    gap: 3rem;
    justify-content: center;
    margin-bottom: 4rem;
    padding: 0 1.5rem;
}
.card-body img{
    width: 30px;
    padding-bottom: 1rem;
}
.card-body{
    font-family: 'Share Tech Mono', monospace;
    padding: 1.5rem 2rem;
    max-height: 420px;
    overflow: auto;
}
.card-title{
    margin-bottom: 0;
    font-size: 28px;
    font-weight: 600;
    color: #142126;
}
.card-text{
    font-family: 'Roboto Condensed', sans-serif;
    overflow: auto;
}
.btn{
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    background-color: #142126;
    color: white;
}
.btn:hover{
    background-color: #9EB5BC;
}
.fade-in-bottom {
	-webkit-animation: fade-in-bottom 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	animation: fade-in-bottom 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}
@-webkit-keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(150px);
            transform: translateY(150px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(150px);
            transform: translateY(150px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}

</style>